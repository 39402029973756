.banner{
    background-color: #1da1f2;
    padding: 20px 0px;
}
.banner h1{
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    color: #fff;
    
}
.banner::after{
    content: '';
    position: absolute;
    height: 50vh;
    left: 0;
    width: 100%;
    top: 0;
    background-color: #1da1f2;
    z-index: -1;
}
.trends-section{
    padding-bottom: 40px;
}
.trends-list{
    padding: 20px 30px;
    border-radius: 20px;
    background: transparent;
backdrop-filter: blur(24px);
--webkit-backdrop-filter: blur(24px);
background-color: rgba(255, 255, 255, 0.76);

}
.trends-item{
    display: flex;
    align-items: center;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(4, 41, 66, 0.11);
}
.trends-item:nth-last-child(1){
    border-bottom:0px;
}
.trends-item-name{
    flex-grow: 1;
    padding-left: 15px;
}
.sl-denote{
    background-color: #1da1f2;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}
.trends-item-name h3{
    font-size: 19px;
    margin: 0px 0px 5px 0px;
    
}
.trends-item-name span{
    font-size: 16px;
}
.trends-item-name h3 a{
    color: #4a4747;
     text-decoration: none;
}
.trends-item-name span{
    color: #4a4747; 
}
.tweet-copy button{
    font-weight: 400;
    font-size: 14px;
}

.load_more {
    margin: 20px 0px;
}
.load_more button{
    font-weight: 400;
}
.border-left{
    border-left:1px solid rgba(4, 41, 66, 0.11);
}
.sidebar h3{
    color: #1da1f2;
    font-weight: 700;
}

.sidebar-link {
    list-style: none;
    padding-left: 0;
}
.sidebar-link li{
    padding: 10px 0px;
    border-bottom: 1px solid rgba(4, 41, 66, 0.11);
}
.sidebar-link li:nth-last-child(1){
    border-bottom: 0px; 
}
.sidebar-link li a{
    text-decoration: none;
    color: #4a4747;
}
.read-more-link{
    background-color: transparent;
    border: none;
    color: #1da1f2;;
}
.citiy-not-found{
    background: #1da1f2;
    text-align: center;
    padding: 50px 0px;
    border-radius: 10px;
}
.citiy-not-found p{
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.citiy-not-found button{
    background: #167cba;
    color: #fff;
    border: 1px solid #1da1f2;
    border-radius: 20px;
    padding: 5px 25px;
}
.menu{
    display: flex;
    align-items: center;
}
.search-bar-wrapper{
    min-width: 244px;
    margin-right: 10px;
}
.jZkBQk{
    height: 36px;
}
.frjscc {
    min-height: 36px;
}
.frjscc > input{
    font-size: 14px;
    padding: 0 0 0 8px;
}
.page-not-found-wrapper{
    width: 100%;
    min-height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.page-not-found{
    min-width: 460px;
    display: flex;
    margin: 0 auto;
    background-color: #1da1f2;
    min-height: 332px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}
.not-found-content{
    margin-bottom: 30px;
}
.not-found-content h1{
    font-size: 100px;
    color: #fff;
}