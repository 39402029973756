body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f0f0f0;
}
.btn{
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
    padding: 5px 25px;
    transition: all 200ms ease-in;
}
.primary-btn{
    background-color: #1da1f2;
    color: #fff;
}
.transparent-btn{
    background-color: #167cba;
}
.transparent-btn:hover{
    border: 1px solid #167cba;
    background-color: #fff;
    color: #167cba;
}
.mg-right10{
    margin-right: 10px;
}
h1{
    font-size: 30px;
    margin: 0px;
}
.border-right{
    border-right:1px solid #424645;
}

