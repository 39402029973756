footer{
    width: 100%;
    height: auto;
    background-color:#171a19;
    padding: 40px 0px 0px;
}
.footer-top{
    border-bottom: 1px solid #424645;
    padding: 0px 0px 40px;
}
.footer-links{
    list-style: none;
    padding: 0px 10px;
}
.footer-links li{
    padding: 10px;
}
.footer-links li a{
    text-decoration: none;
    color: #fff;
}
.social-link{
    padding: 0px 10px;
}

.social-link h3{
    color:#1da1f2;
}
.social-link ul{
    list-style: none;
    padding-left: 0;
}
.social-link ul li{
    display: inline-block;
    background: #f0f0f0;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
}
.social-link ul li a{
    color: #000;
    width: 100%;
    height: 100%;
}
.footer-bottom{
    text-align: center;
    padding: 20px 0px;
}

.footer-bottom p{
    color: #fff;
    margin-bottom: 0px;
    font-size: 14px;
}
.footer-bottom p a{
    color: #fff;
    text-decoration: none;
}