header{
    width: 100%;
    height: auto;
    background-color:#1da1f2;
    padding: 10px 0px;
    position: relative;
    z-index: 1;
    border-bottom: 0.5px solid #ffffff3b;
}

.header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}